import React from 'react';
import { Box, Flex, Link } from '@chakra-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { faGithub, faLinkedin } from '@fortawesome/free-brands-svg-icons';

const IconLink = ({ icon, ariaLabel, href }) => {
  return (
    <Link aria-label={ariaLabel} href={href} target="_blank" rel="noopener" mr="24">
      <Box fontSize="5xl">
        <FontAwesomeIcon icon={icon} />
      </Box>
    </Link>
  );
};

const ContactLinks = () => {
  return (
    <Flex minHeight="72px">
      <IconLink ariaLabel="GitHub profile" icon={faGithub} href="https://github.com/michael-mao" />
      <IconLink ariaLabel="LinkedIn profile" icon={faLinkedin} href="https://www.linkedin.com/in/m-mao" />
      <IconLink ariaLabel="Send email" icon={faEnvelope} href="mailto:m.mao@outlook.com" />
    </Flex>
  );
};

export default ContactLinks;
