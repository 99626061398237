import React from 'react';
import { Box, Flex } from '@chakra-ui/core';
import Layout from '../components/Layout';
import Introduction from '../components/Introduction';
import ContactLinks from '../components/ContactLinks';

const HomePage = () => {
  return (
    <Layout>
      <Flex as="section" flexDirection="column" justifyContent="center" minHeight="calc(100vh - 56px)">
        <Introduction />
        <ContactLinks />
        <Box height="25vh" />
      </Flex>
    </Layout>
  );
};

export default HomePage;
