import React from 'react';
import { Box, Heading } from '@chakra-ui/core';

const Introduction = () => {
  return (
    <Box mb={12}>
      <Heading as="h1" fontSize="5xl">
        Hi, my name is{' '}
        <Heading as="span" fontSize="5xl" color="teal.400">
          Michael Mao
        </Heading>{' '}
        👋
        <br />
        I'm a full-stack web developer.
      </Heading>
    </Box>
  );
};

export default Introduction;
